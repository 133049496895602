@import url(../../globalCSS/vars.css);

.steps {
  padding: 60px 0;
  border-top: 1px solid #E4E4E4;
}
.steps__title {
  font-size: 64px;
  color: var(--blue);
  text-align: center;
}
.steps__content {
  display: flex;
  flex-direction: column;
  position: relative;
}
.steps__container > *:not(:first-child) {
  margin-top: 70px;
}
.steps__card-wrap {
  display: flex;
  justify-content: flex-end;
}
.steps__card-wrap:nth-child(2n) {
  justify-content: flex-start;
}
.steps__card-wrap:nth-child(2n) .steps__number {
  left: auto;
  right: 10px;
}
.steps__cards > .steps__card-wrap:first-child {
  margin-top: 80px;
}
.steps__cards > .steps__card-wrap:last-child {
  margin-bottom: 80px;
}
.steps__card {
  width: 45%;
  background-color: #fff;
  box-shadow: -1px 2px 55px -10px rgba(0,0,0,0.75);
  padding: 50px 60px;
  border-radius: 10px;
  position: relative;
}
.steps__number {
  position: absolute;
  width: 43px;
  height: 43px;
  background-color: #D9D9D9;
  border-radius: 50%;
  font-size: 32px;
  color: var(--blue);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 10px;
  top: 10px;
}
.steps__image {
  width: 100%;
}
.steps__txt-content {
  margin-top: 20px;
}
.steps__title-card {
  font-size: 20px;
  color: var(--blue)
}
.steps__txt-content > *:not(:first-child) {
  margin-top: 10px;
}
.steps__line {
  position: absolute;
  height: 100%;
  width: 10px;
  background-color: #D9D9D9;
  border-radius: 5px;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  overflow: hidden;
}
.steps__line-inner {
  transition: height 0.1s ease 0s;
  background-color: var(--blue);
  height: 50%;
}
.steps__image {
  border-radius: 8px;
}

@media (max-width: 1300px) {
  .steps__title {
    font-size: 56px;
  }
}
@media (max-width: 1024px) {
  .steps__number {
    width: 35px;
    height: 35px;
    font-size: 25px;
  }
  .steps__card {
    padding: 50px 45px;
  }
}
@media (max-width: 767.9px) {
  .steps__title {
    font-size: 50px;
  }
  .steps__card {
    width: 80%;
  }
  .steps__card-wrap {
    display: flex;
    justify-content: center !important;
  }
  .steps__cards > *:not(:first-child) {
    margin-top: 60px;
  }
  .steps__card {
    box-shadow: -1px 2px 35px -10px rgba(0,0,0,0.75);
  }
  .steps__number {
    width: 45px;
    height: 45px;
    font-size: 30px;
    left: 5px;
    right: 5px;
  }
  .steps__card {
    padding: 60px 30px;
  }
  .steps__title-card {
    font-size: 24px;
  }
}
@media (max-width: 630px) {
  .steps__card {
    width: 90%;
  }
}
@media (max-width: 450px) {
  .steps__number {
    width: 35px;
    height: 35px;
    font-size: 24px;
  }
  steps__container > *:not(:first-child) {
    margin-top: 50px;
  } 
  .steps__cards > .steps__card-wrap:first-child {
    margin-top: 40px;
  }
  .steps__cards > .steps__card-wrap:last-child {
    margin-bottom: 40px;
  }
}