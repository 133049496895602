.about {
  padding-top: 40px;
  background-image: url(../../assets/About/bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
}
.about__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about__title {
  font-size: 64px;
  color: #fff;
  border-bottom: 4px solid #fff;
  padding-bottom: 5px;
}
.about__content {
  display: flex;
  align-items: flex-end;
  gap: 16px;
}
.about__human {
  width: 505px;
}
.about__content-text {
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding-bottom: 20px;
}
.about__content-text > * {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding: 43px 80px;
}
.about__container > *:not(:first-child) {
  margin-top: 40px;
}
.about__main {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;
}
.about__main-title-wrap {
  display: flex;
  align-items: center;
  gap: 30px;
}
.about__logo {
  width: 77px;
  height: auto;
}
.about__main-title {
  font-family: "Comorant";
  font-weight: 500;
  color: #fff;
  font-size: 64px;
  border-bottom: 2px solid var(--blue);
  padding-bottom: 5px;
}
.about__main-title span {
  color: #26B3E2;
}
.about__txt {
  color: #E1E1E1;
  text-align: center;
}
.about__items {
  display: flex;
}
.about__content-text .about__items {
  padding: 30px 0;
}
.about__items > .about__item:not(:first-child) {
  border-left: 2px solid var(--blue);
}
.about__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 0 30px;
  max-width: 34%;
}
.about__icon {
  width: 130px;
  height: auto;
}
.about__txt-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}



@media (max-width: 1370px) {
  .about__main-title {
    font-size: 58px;
  }
  .about__human {
    width: 475px;
  }
}
@media (max-width: 1300px) {
  .about__txt {
    font-size: 22px;
  }
  .about__content-text > * {
    padding: 43px 60px;
  }
  .about__item {
    padding: 0 20px;
  }
  .about__main-title {
    font-size: 50px;
  }
  .about__icon {
    width: 110px;
    height: auto;
  }
}
@media (max-width: 1200px) {
  .about__logo {
    width: 68px;
  }
  .about__main-title {
    font-size: 44px;
  }
  .about__human {
    width: 410px;
  }
}
@media (max-width: 1070px) {
  .about__txt {
    font-size: 20px;
  }
  .about__main-title {
    font-size: 40px;
  }
  .about__icon {
    width: 95px;
  }
  .about__main {
    gap: 10px;
  }
}
@media (max-width: 1036px) {
  .about__icon {
    width: 85px;
  }
  .about__main-title {
    font-size: 37px;
  }
}
@media (max-width: 1024px) {
  .about__human {
    display: none;
  }
  .about__content-text {
    padding-bottom: 50px;
  }
  .about__main-title {
    font-size: 44px;
  }
  .about__txt {
    font-size: 24px;
  }
  .about__icon {
    width: 110px;
  }
  .about__txt-wrap {
    gap: 15px;
  }
}

@media (max-width: 680px) {
  .about__txt {
    font-size: 22px;
  }
  .about__title {
    font-size: 56px;
  }
}
@media (max-width: 600px) {
  .about__icon {
    width: 120px;
  }
  .about__txt {
    font-size: 24px;
  }
  .about__main-title {
    font-size: 40px;
  }
  .about__main-title-wrap {
    flex-direction: column;
    gap: 10px;
  }
  .about__content-text > * {
    padding: 43px;
  }
  .about__items {
    flex-direction: column;
    align-items: center;
  }
  .about__items > *, .about__items > .about__item:not(:first-child) {
    border-left: none;
  }
  .about__items > *:not(:first-child) {
    border-top: 2px solid var(--blue);
    padding-top: 20px;
  }
  .about__items > *:not(:last-child) {
    padding-bottom: 20px;
  }
  .about__item {
    max-width: 80%;
  }
  .about__content-text .about__items {
    padding: 50px 0;
  }
  .about__logo {
    width: 105px;
  }
  .about__main {
    gap: 30px;
  }
}

@media (max-width: 455px) {
  .about__main-title {
    font-size: 34px;
  }
}

@media (max-width: 397px) {
  .about__main-title {
    font-size: 30px;
  }
  .about__content-text > * {
    padding: 35px;
  }
}
@media (max-width: 349px) {
  .about__main-title {
    font-size: 26px;
  }
  .about__txt {
    font-size: 20px;
  }
}