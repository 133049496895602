header.header {
  padding: 25px 0;
  position: fixed;
  z-index: 100;
  background-color: #fff;
  width: 100%;
  transition: box-shadow 0.3s ease 0s;
}
.header._scrolled {
  box-shadow: 0px 9px 40px -34px rgba(0,0,0,0.75);
}
.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.header__logo {
  width: 72px;
  height: 110px;
}
.header__nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}
.header__link {
  color: #000;
  transition: opacity 0.5s ease 0s;
}
.header__link:hover {
  opacity: 0.7;
}
.header__tel {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 22px;
  font-weight: 700;
  font-family: "Century Gothic";
  color: #1393BE;
}
.menu__body {
  display: flex;
  justify-content: space-between;
  /* gap: 88px; */
  gap: 50px;
}
.menu__icon {
  display: none;
}
.header__tel-wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
}
.header__tel-icon {
  width: 26px;
  height: 26px;
}
/*  */

.header__lang {
  background-color: rgba(0,0,0,0.6);
  padding: 2px 7px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}
.lang__flag {
  width: 50px;
  height: auto;
  border-radius: 5px;
}
.header__logo-lang {
  display: flex;
  align-items: center;
  gap: 30px;
}
.lang__trinagle {
  width: 40px;
  user-select: none;
  height: auto;
  transition: transform 0.3s ease 0s;
}
.header__lang._active .lang__trinagle {
  transform: rotate(180deg);
}
.lang__select {
  position: absolute;
  top: 55px;
  right: 0;
  background-color: rgba(0,0,0,0.6);
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
  cursor: auto;
}
.lang__select .lang__flag {
  cursor: pointer;
}
.header__lang._active .lang__select {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 1470px) {
  ._rus .header__link {
    font-size: 22px;
  }
}

@media (max-width: 1419px) {
  .menu__body {
    gap: 50px;
  }
  .header__link {
    font-size: 22px;
  }
}

@media (max-width: 1405px) {
  ._rus .menu__body {
    gap: 40px;
  }
  ._rus .header__nav {
    gap: 20px;
  }
  ._rus .lang__flag {
    width: 50px;
  }
}

@media (max-width: 1345px) {
  ._rus .header__tel {
    font-size: 20px;
  }
}

@media (max-width: 1330px) {
  .header__link {
    font-size: 20px;
  }
  .header__tel-txt {
    font-size: 20px;
  }
  .header__tel-icon {
    width: 23px;
    height: 23px;
  }
}

@media (max-width: 1318px) {
  ._rus .header__link {
    font-size: 20px;
  }
  ._rus .lang__trinagle {
    width: 30px;
  }
  ._rus .header__tel-icon {
    width: 20px;
    height: auto;
  }
}


@media (max-width: 1246px) {
  .header__link {
    font-size: 19px;
  }
  .header__tel-txt {
    font-size: 18px;
  }
  ._rus .header__tel-txt {
    font-size: 16px;
  }
  .header__nav {
    gap: 25px;
  }
}

@media (max-width: 1216px) {
  ._rus .header__nav {
    gap: 15px;
  }
  ._rus .lang__flag {
    width: 40px;
    border-radius: 3px;
  }
  ._rus .lang__trinagle {
    width: 25px;
  }
  ._rus .menu__body {
    gap: 25px;
  }
  ._rus .header__logo-lang {
    gap: 20px;
  } 
  ._rus .header__link {
    font-size: 18px;
  }
}

@media (max-width: 1160px) {
  ._il .header__link {
    font-size: 16px;
  }
  ._il .menu__body {
    gap: 40px;
  }
}

@media (max-width: 1138px) {
  .header__link {
    font-size: 17px;
  }
  .header__tel-txt {
    font-size: 16px;
  }
  .header__logo {
    width: 62px;
    height: 92px;
  }
  .main-section {
    margin-top: 143px;
  }
}

@media (max-width: 1077px) {
  ._rus .header__link {
    font-size: 16px;
  }
}


@media (max-width: 1060px) {
  .header__nav {
    gap: 20px;
  }
}
@media (max-width: 1024px) {
  .menu__body {
    position: fixed;
    height: calc(100vh - 135px);
    width: 100%;
    left: -100vw;
    top: 153px;
    background-color: rgba(0,0,0,0.85);
    padding: 50px 30px;
    z-index: 5;
    transition: left 0.3s ease 0s;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
  }
  .main-section {
    margin-top: 135px;
  }
  .header {
    padding: 15px 0;
  }
  .header__tel-wrap {
    align-items: center;
  }
  .menu__icon {
    display: block;
    position: relative;
    width: 50px;
    height: 30px;
    cursor: pointer;
  }
  .menu__icon span, .menu__icon::after, .menu__icon::before {
    position: absolute;
    width: 100%;
    height: 10%;
    background-color: #000;
    transition: all 0.3s ease 0s;
  }
  .menu__icon span {
    top: 50%;
    transform: translateY(-50%), scale(1);
  }
  .menu__icon::after, .menu__icon::before {
    content: "";
  }
  .menu__icon::before {
    top: 0;
  }
  .menu__icon::after {
    bottom: 0;
  }
  .header__menu._active .menu__body {
    left: 0;
  }
  .header__menu._active .menu__icon span {
    transform: scale(0);
  }
  .header__menu._active .menu__icon::before {
    transform: rotate(-45deg);
    top: 50%;
  }
  .header__menu._active .menu__icon::after {
    transform: rotate(45deg);
    bottom: 42%;
  }
  .header__logo {
    width: 75px;
    height: 105px;
  }
  .header__nav {
    flex-direction: column;
    gap: 30px;
  }
  .header__link, ._rus .header__link, ._il .header__link {
    color: #fff;
    font-size: 30px;
  }
  .header__tel-txt, ._rus .header__tel-txt {
    font-size: 30px;
  }
  .header__tel-icon {
    width: 30px;
    height: 30px;
  }
  ._rus .lang__flag {
    width: 60px;
    border-radius: 5px;
  }
  ._rus .lang__trinagle {
    width: 40px;
  }
  .header__logo-lang {
    gap: 20px;
  }
  .lang__select, .lang__select a {
    width: 100%;
  }
  .lang__select a {
    display: flex;
    justify-content: center;
  }
  .lang__select img.lang__flag {
    width: 80%;
  }
  .lang__select {
    padding: 10px;
  }
  .header__logo-lang {
    width: 60%;
    justify-content: space-between;
  }
}
@media (max-width: 435px) {
  .header__logo {
    width: 62px;
    height: 85px;
  }
  .main-section {
    margin-top: 115px;
  }
  .menu__body {
    height: calc(100vh - 115px);
    top: 133px;
  }
}

@media (max-width: 380px) {
  .header__link {
    font-size: 28px;
  }
  .header__tel-txt {
    font-size: 24px;
  }
  .header__nav {
    gap: 25px;
  }
  .menu__body {
    gap: 30px;
  }
  .header__tel-icon {
    width: 26px;
  }
}