/***************************************
*****************шрифты*****************
****************************************/

@font-face {
  font-family: 'Futura PT';
  src: url('../public/fonts/FuturaPT-Book.woff') format('woff'),
        url('../public/fonts/FuturaPT-Book.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Futura PT';
  src: url('../public/fonts/FuturaPT-Demi.woff') format('woff'),
        url('../public/fonts/FuturaPT-Demi.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Futura PT';
  src: url('../public/fonts/FuturaPT-Heavy.woff') format('woff'),
        url('../public/fonts/FuturaPT-Heavy.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: 'Evolventa';
  src: url('../public/fonts/Evolventa-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Evolventa';
  src: url('../public/fonts/Evolventa-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Robotolight.woff') format('woff'),
        url('../public/fonts/Robotolight.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Cormorant';
  src: url('../public/fonts/Cormorant-Medium.woff') format('woff'),
        url('../public/fonts/Cormorant-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Segoe UI';
  src: url('../public/fonts/segoe_ui_light.woff') format('woff'),
        url('../public/fonts/segoe_ui_light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Segoe UI';
  src: url('../public/fonts/segoe_ui_semibold.woff') format('woff'),
        url('../public/fonts/segoe_ui_semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: 'Century Gothic';
  src: url('../public/fonts/century_gothic_gras.woff') format('woff'),
        url('../public/fonts/century_gothic_gras.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}



/***************************************
**********сброс и заготовки*************
***************************************/
@import url(globalCSS/vars.css);

*, *::before, *::after {
	padding: 0;
	margin: 0;
	border: 0;
	box-sizing: border-box;
}
a {
	text-decoration: none;
  color: inherit;
}
ul, ol, li {
	list-style: none;
}
img {
	vertical-align: top;
}
html, body {
	height: 100%;
	line-height: 1.2;
	font-size: var(--base);
	color: var(--gray);
  font-family: 'Futura PT', sans-serif;
  font-weight: 400;
  scroll-behavior: smooth;
}
._wrapper {
	min-height: 100vh;
	overflow: hidden;
	max-width: 100%;
	display: flex;
	flex-direction: column;
  position: relative;
}
img {
	display: block;
}
._container {
	margin: 0 auto;
	max-width: 1410px;
	padding: 0 20px;
	box-sizing: content-box;
  position: relative;
}
.main {
	flex: 1 1 auto;
  padding: 40px 0;
}
body._lock {
  overflow: hidden;
}
._wrapper > * {
  position: relative;
}
._fake {
  position: absolute;
  width: 100%;
  height: 1px;
  top: -160px;
}

@media (max-width: 1024px) {
  ._fake {
    top: -154px;
  }
}
@media (max-width: 435px) {
  ._fake {
    top: -134px;
  }
}
